import React from "react";
import './index.css'
import text from '../../static/upgrading.svg'
import logo from '../../static/logo.svg'
import {openNewWindow} from "../../static/Utils";
export default function BihelixHome(){
    return (
        <div className='home-bg'>
            <div className='hb-main flex-column flex-center'>
                <div className='flex-row fl-ai-center'>
                    <img src={logo} width={160} height={40}/>
                </div>
                <img src={text} width={1230} height={84} style={{marginTop:'60px'}}/>
                <div style={{color:'#BDBDBD',fontSize:'24px',marginTop:'28px'}}>Massive Pump Coming Soon</div>
                <div className='hb-button flex-center' onClick={()=>window.location.href='https://t.me/bihelix'}>Follow up now</div>
            </div>
        </div>
    )
}
